export default function Footer() {
  return (
    <footer className="pv4 ph3 ph5-m ph6-l mid-gray">
      <small className="f6 db tc">
        © 2020 <b className="ttu">Ben Pyrik</b>., All rights reserved.
      </small>
      <div className="tc mt3">
        <a
          href="https://pyrik.dev/"
          title="pyrik.dev"
          className="f6 dib ph2 link mid-gray dim"
        >
          pyrik.dev
        </a>
      </div>
    </footer>
  );
}
