import { Link } from "@reach/router";

import Header from "../../components/Header";
import Footer from "../../components/Footer";

interface AboutProps {
  path?: string;
}

export default function About(props: AboutProps) {
  return (
    <div
      className="helvetica pa3 ma3"
      style={{ margin: "0 auto", maxWidth: "960px" }}
    >
      <Header link="/offline/projects" linkText="projects ->" />

      <blockquote className="ml0 mt0 pl4 black-90 bl bw2 b--blue mt3">
        <p className="f5 lh-copy measure mt0">
          Analysis of competing hypotheses, sometimes abbreviated ACH, is a tool
          to aid judgment on important issues requiring careful weighing of
          alternative explanations or conclusions. It helps an analyst overcome,
          or at least minimize, some of the cognitive limitations that make
          prescient intelligence analysis so difficult to achieve.
        </p>
        <cite className="f6 ttu tracked fs-normal">
          -Richards J. Heuer, Jr.
        </cite>
      </blockquote>

      <p className="f5 lh-copy measure mt4">
        This website automates parts of the ACH procedure and provides an easy
        interface to collect hypotheses and evidence. All data is stored in your
        browser, nothing is saved to external servers.
      </p>

      <p className="f5 lh-copy measure">
        To learn how ACH works, refer to{" "}
        <a
          className="link"
          href="https://www.cia.gov/library/center-for-the-study-of-intelligence/csi-publications/books-and-monographs/psychology-of-intelligence-analysis/art11.html"
        >
          Chapter 8 from Psychology of Intelligence Analysis
        </a>{" "}
        (available for free, courtesy of the CIA).
      </p>

      <p className="f5 lh-copy measure mt4">
        To get started,{" "}
        <Link className="link b" to="/offline/projects">
          click here to go to the projects page
        </Link>
        .
      </p>

      <Footer />
    </div>
  );
}
