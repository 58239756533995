import { useState, useEffect } from "react";
import cogoToast from "cogo-toast";
import { DateTime } from "luxon";
import { getMany, set, setMany, clear, del } from "idb-keyval";

import * as Ach from "../../ach";
import Header from "../../components/Header";
import usePersistentState from "../../hooks/usePersistentState";
import ProjectList from "./components/ProjectList";
import FileUploader from "../../components/FileUploader";
import Footer from "../../components/Footer";

const initialProjectIds: string[] = [];

interface ProjectsProps {
  path?: string;
}

export default function Projects(props: ProjectsProps) {
  const [projectIds, setProjectIds] = usePersistentState(
    "projectIds",
    initialProjectIds
  );
  const [projects, setProjects] = useState<Ach.Project[]>([]);

  useEffect(() => {
    getMany(projectIds)
      .then((_projects) => {
        setProjects(_projects);
      })
      .catch((error) => {
        cogoToast.error("Error retrieving projects, check the console.");
        console.error(error);
      });
  }, [projectIds]);

  const handleAddProjectButtonClick = () => {
    const newProject = Ach.Reducer.init();
    set(newProject.id, newProject)
      .then(() => {
        setProjectIds((current: string[]) => {
          return [...current, newProject.id];
        });
      })
      .catch((error) => {
        cogoToast.error("Error adding a new project, check the console.");
        console.error(error);
      });
  };

  const addSampleProject = () => {
    // Insert the sample project directly into IndexedDB.
    const sampleProject = Ach.createSampleProject();
    set(sampleProject.id, sampleProject)
      .then(() => {
        setProjectIds((current: string[]) => {
          return [...current, sampleProject.id];
        });
      })
      .catch((error) => {
        cogoToast.error("Error adding sample project, check the console.");
        console.error(error);
      });
  };

  const handleProjectDeleteButtonClick = (
    projectIndex: number,
    projectId: string
  ) => {
    const confirmation = window.confirm(
      "Are you sure you want to delete this project? This cannot be undone."
    );
    if (!confirmation) {
      return;
    }

    del(projectId)
      .then(() => {
        setProjectIds((current: string[]) => {
          const newProjectIds = [...current];
          newProjectIds.splice(projectIndex, 1);
          return newProjectIds;
        });
      })
      .catch((error) => {
        cogoToast.error("Error deleting project, check the console.");
        console.error(error);
      });
  };

  const handleExportButtonClick = () => {
    // Save to disk
    const blob = new Blob([JSON.stringify(projects, null, 2)], {
      type: "application/json;charset=utf-8",
    });
    const now = DateTime.local();
    saveAs(blob, `${now.toISODate()}-projects.json`);
    cogoToast.success("Projects exported");
  };

  const handleUploadButtonClick = () => {
    // If there is project data, ask the user to confirm the import action.
    if (projects.length > 0) {
      return window.confirm(
        "Are you sure you want to upload projects? If two projects share the same ID, the uploaded project will replace the project stored in your browser. This cannot be undone."
      );
    }

    // The user has no projects, so importing is non-destructive.
    return true;
  };

  const handleUpload = (file: string) => {
    // Try to parse uploaded file
    let uploadedProjects: Ach.Project[];
    try {
      uploadedProjects = JSON.parse(file).map((project: JSON) =>
        Ach.parseProject(project)
      );
    } catch (error) {
      cogoToast.error("Uploaded projects could not be parsed.");
      console.error(error);
      return;
    }

    // Save all imported projects
    const uploadedProjectEntries: [
      string,
      Ach.Project
    ][] = uploadedProjects.map((p: Ach.Project) => [p.id, p]);
    setMany(uploadedProjectEntries)
      .then(() => {
        // Save projectIds
        const uploadedProjectIds = uploadedProjects.map((p) => p.id);
        setProjectIds(uploadedProjectIds);
        cogoToast.info(`${uploadedProjects.length} projects uploaded`);
      })
      .catch((error) => {
        cogoToast.error("Project upload error, check the console.");
        console.error(error);
      });
  };

  const handleClear = () => {
    const confirmation = window.confirm(
      "Are you sure you want to delete all local data? This cannot be undone."
    );
    if (confirmation) {
      clear()
        .then(() => {
          setProjectIds([]);
          cogoToast.success("Local data cleared");
        })
        .catch((error) => {
          cogoToast.error("Error clearing local data, check the console.");
          console.error(error);
        });
    }
  };

  return (
    <div
      className="helvetica pa3 ma3"
      style={{ margin: "0 auto", maxWidth: "960px" }}
    >
      <Header link="/about" linkText="<- about" />

      <h2 className="f3 lh-title measure">Projects</h2>
      <ProjectList
        projects={projects}
        handleProjectDeleteButtonClick={handleProjectDeleteButtonClick}
        addSampleProject={addSampleProject}
      />

      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-near-black fr b--none pointer"
        onClick={handleAddProjectButtonClick}
      >
        Add project
      </button>

      <details className="pointer">
        <summary style={{ lineHeight: "normal" }}>Advanced</summary>
        <div className="mb3 mt3">
          <FileUploader
            uploadButtonLabel="upload json"
            onUploadButtonClick={handleUploadButtonClick}
            onUpload={handleUpload}
          />
          <button
            className="f6 dim br2 ph3 pv2 mb2 dib white bg-near-black ml2 b--none pointer"
            onClick={handleExportButtonClick}
          >
            export json
          </button>
          <button
            className="f6 dim br2 ph3 pv2 mb2 dib white bg-red ml2 b--none pointer"
            onClick={handleClear}
          >
            clear
          </button>
        </div>
      </details>

      <Footer />
    </div>
  );
}
