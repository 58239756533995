import HypothesisListElement from "./HypothesisListItem";

interface HypothesisListProps {
  hypotheses: string[];
  dispatch: any;
  highlightIndex?: number;
}
export default function HypothesisList({
  hypotheses,
  dispatch,
  highlightIndex,
}: HypothesisListProps) {
  if (hypotheses.length === 0) {
    return (
      <h4 className="f5 fw6">
        Hypotheses:{" "}
        <span className="f5 lh-copy normal">
          None yet, click the add button below!
        </span>
      </h4>
    );
  }

  const hypothesisEls = hypotheses.map((hypothesis, i) => {
    return (
      <HypothesisListElement
        key={i}
        index={i}
        hypothesis={hypothesis}
        dispatch={dispatch}
        highlighted={i === highlightIndex}
      />
    );
  });

  return (
    <>
      <h4 className="f5 fw6">Hypotheses:</h4>
      {hypothesisEls}
      <div className="bb b--black-20 mb1 mt3" />
    </>
  );
}
