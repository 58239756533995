import { v4 as uuidv4 } from "uuid";
import { DateTime } from "luxon";
import produce from "immer";

import * as Ach from "./types";

export const init = (initial?: Ach.Project): Ach.Project => {
  return (
    initial ?? {
      id: uuidv4(),
      lastUpdated: DateTime.local().toISO(),
      title: "",
      hypotheses: [],
      evidence: [],
      evidenceIndexToCredibility: [],
      evidenceIndexToRelevance: [],
      consistencyRatings: [],
    }
  );
};

export type Action =
  | { type: "clear" }
  | { type: "import"; project: Ach.Project }
  | { type: "title.set"; title: string }
  | { type: "hypothesis.add"; hypothesis: string }
  | {
      type: "hypothesis.replaceByIndex";
      index: number;
      newHypothesis: string;
    }
  | {
      type: "hypothesis.deleteByIndex";
      index: number;
      hypothesis: string;
    }
  | { type: "evidence.add"; evidence: string }
  | { type: "evidence.replaceByIndex"; index: number; newEvidence: string }
  | { type: "evidence.deleteByIndex"; index: number }
  | {
      type: "evidence.credibility.set";
      index: number;
      credibility: Ach.Credibility;
    }
  | {
      type: "evidence.relevance.set";
      index: number;
      relevance: Ach.Relevance;
    }
  | {
      type: "consistencyRating.set";
      hypothesisIndex: number;
      evidenceIndex: number;
      rating: Ach.ConsistencyRating;
    };

export const reducer = (state: Ach.Project, action: Action): Ach.Project => {
  switch (action.type) {
    case "clear":
      // Reset all project data to default values EXCEPT for the project ID.
      return { ...init(), id: state.id };
    case "import":
      // Assumption: validation was done externally.
      return action.project;
    case "title.set":
      return {
        ...state,
        title: action.title,
        lastUpdated: DateTime.local().toISO(),
      };
    case "hypothesis.add":
      return produce(state, (draft) => {
        // We've got a new hypothesis, so add it.
        draft.hypotheses.push(action.hypothesis);

        // Add default consistency ratings.
        let consistencyRatings: any = [];
        for (let i = 0; i < state.evidence.length; i++) {
          consistencyRatings[i] = Ach.ConsistencyRating.N;
        }
        draft.consistencyRatings.push(consistencyRatings);

        draft.lastUpdated = DateTime.local().toISO();
      });
    case "hypothesis.replaceByIndex":
      return produce(state, (draft) => {
        draft.hypotheses[action.index] = action.newHypothesis;

        // Replacements can be thought of as edits, so the consistency ratings
        // associated with the old hypothesis now belong to the new hypothesis.
        // If the user changes one letter, the new hypothesis is still conceptually
        // equivalent to the old hypothesis.
        // Since the new hypothesis has the same index as the old hypothesis, we
        // don't need to do anything.

        draft.lastUpdated = DateTime.local().toISO();
      });
    case "hypothesis.deleteByIndex":
      return produce(state, (draft) => {
        // Remove it from the hypotheses array.
        draft.hypotheses.splice(action.index, 1);

        // Remove it from consistency ratings
        draft.consistencyRatings.splice(action.index, 1);

        draft.lastUpdated = DateTime.local().toISO();
      });
    case "evidence.add":
      return produce(state, (draft) => {
        // We've got new evidence, so add it.
        draft.evidence.push(action.evidence);

        // Add default credibility and relevance
        draft.evidenceIndexToCredibility.push(Ach.Credibility.Medium);
        draft.evidenceIndexToRelevance.push(Ach.Relevance.Medium);

        // New evidence means new consistency ratings.
        for (let ratings of Object.values(draft.consistencyRatings)) {
          ratings.push(Ach.ConsistencyRating.N);
        }

        draft.lastUpdated = DateTime.local().toISO();
      });
    case "evidence.replaceByIndex":
      return produce(state, (draft) => {
        draft.evidence[action.index] = action.newEvidence;

        draft.lastUpdated = DateTime.local().toISO();
      });
    case "evidence.deleteByIndex":
      return produce(state, (draft) => {
        draft.evidence.splice(action.index, 1);
        // Remove all consistency ratings for this piece of evidence
        for (let ratings of draft.consistencyRatings) {
          ratings.splice(action.index, 1);
        }

        draft.lastUpdated = DateTime.local().toISO();
      });
    case "evidence.credibility.set":
      return produce(state, (draft) => {
        draft.evidenceIndexToCredibility[action.index] = action.credibility;

        draft.lastUpdated = DateTime.local().toISO();
      });
    case "evidence.relevance.set":
      return produce(state, (draft) => {
        draft.evidenceIndexToRelevance[action.index] = action.relevance;

        draft.lastUpdated = DateTime.local().toISO();
      });
    case "consistencyRating.set":
      return produce(state, (draft) => {
        draft.consistencyRatings[action.hypothesisIndex][action.evidenceIndex] =
          action.rating;

        draft.lastUpdated = DateTime.local().toISO();
      });
    default:
      console.warn(`Ach.reducer encountered an unknown action: ${action}`);
      return state;
  }
};

/**
 * Operates exactly the same as the regular reducer except it logs the action,
 * current state, and next state to the console every dispatch.
 */
export const debugReducer = (state: Ach.Project, action: Action) => {
  console.log(`Action type: ${action.type}`);
  console.log("Current state", state);
  const nextState = reducer(state, action);
  console.log("Next state", nextState);
  console.log("~~~");
  return nextState;
};
