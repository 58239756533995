import { useCallback } from "react";

import usePersistentState from "./usePersistentState";

/**
 * The reducer form of usePersistentState().
 *
 * @param key A unique identifier for the value to store in IndexedDB.
 * @param reducer Make sure this value is memoized or static!
 * @param initialValue The value to use if IndexedDB does not yet contain this key/value.
 *  Make sure this value is memoized or static!
 */
export default function usePersistentReducer(
  key: string,
  reducer: (state: any, action: any) => any,
  initialState: any
) {
  const [state, setState] = usePersistentState(key, initialState);

  const dispatch = useCallback(
    (action: any) => {
      const nextState = reducer(state, action);
      setState(nextState);
    },
    [state, reducer, setState]
  );

  return [state, dispatch];
}
