import * as z from "zod";

export enum Credibility {
  Low,
  Medium,
  High,
}

export enum Relevance {
  Low,
  Medium,
  High,
}

export enum ConsistencyRating {
  II, // Very inconsistent
  I, // Inconsistent
  N, // Neutral
  C, // Consistent
  CC, // Very consistent
}

/** e.g. "Iraq will not retaliate" */
export type Hypothesis = string;

/** e.g. Saddam public statement of intent not to retaliate. */
export type Evidence = string;

export interface Project {
  /** The project ID is a UUID. */
  id: string;
  /** ISO 8601 DateTime string e.g. "2021-02-04T19:32:25.241-07:00" */
  lastUpdated: string;
  /** e.g. "Will Iraq Retaliate for US Bombing of Its Intelligence Headquarters?" */
  title: string;
  /**
   * - Altering the order of this array must be done with care as indices
   * are effectively hypothesis IDs
   */
  hypotheses: Hypothesis[];
  /**
   * - Altering the order of this array must be done with care as indices
   * are effectively evidence IDs
   */
  evidence: Evidence[];
  /** Maps a piece of evidence to an assement of credibility. */
  evidenceIndexToCredibility: Credibility[];
  /** Maps a piece of evidence to an assement of reliability. */
  evidenceIndexToRelevance: Relevance[];
  /** consistencyRatings[hypothesisId][evidenceId] == ConsistencyRating */
  consistencyRatings: ConsistencyRating[][];
}

/**
 * Tries to parse a JSON object as an Ach.Project.
 * Will throw an error if the JSON object does not have the right properties and
 * types.
 * @param rawData
 */
export const parseProject = (rawData: JSON): Project => {
  const projectSchema = z.object({
    id: z.string(),
    lastUpdated: z.string(),
    title: z.string(),
    hypotheses: z.array(z.string()),
    evidence: z.array(z.string()),
    evidenceIndexToCredibility: z.array(z.number()),
    evidenceIndexToRelevance: z.array(z.number()),
    consistencyRatings: z.array(z.array(z.number())),
  });
  return projectSchema.parse(rawData);
};
