import { useState, useEffect, useRef, useCallback } from "react";
import { get, set } from "idb-keyval";
import { dequal } from "dequal";

import useAppFocus from "../useAppFocus";
import { registerGlobalState, GlobalStateRegistration } from "./globalState";

/**
 * Helper method that synchronizes local state with IndexedDB.
 */
const _synchronize = async (key: string, initialValue: any, setState: any) => {
  const storedValue = await get(key);

  if (storedValue === undefined) {
    // Either the key could not be found or the key pointed to 'undefined'.
    // Either way, fallback to the initial value.
    await set(key, initialValue);
    setState(initialValue);
    return;
  }

  // A value was found, so update state (if it's different)
  setState((current: any) => {
    if (dequal(current, storedValue)) {
      // The local state is equivalent to the value found in IndexedDB,
      // so return the current state (React will bailout of the update).
      return current;
    }
    return storedValue;
  });
};

/**
 * Like useState(), except state is persisted to IndexedDB and synched across
 * tabs and component instances.
 *
 * Based on: https://github.com/donavon/use-persisted-state/blob/develop/src/usePersistedState.js
 *
 * @param key A unique identifier for the value to store in IndexedDB.
 * @param initialValue The value to use if IndexedDB does not yet contain this key/value.
 *  Make sure this value is memoized or static!
 */
export default function usePersistentState(key: string, initialValue: any) {
  const globalStateRegistration = useRef<GlobalStateRegistration>();
  const [state, setState] = useState(() => initialValue);

  // Initialization.
  // This effect should only run once.
  useEffect(() => {
    // Try to recover state from IndexedDB.
    _synchronize(key, initialValue, setState);

    // Subscribe to changes to this key/value. If another component using this
    // hook (with the same key) changes state, we want to hear about it and update to match.
    globalStateRegistration.current = registerGlobalState(
      key,
      setState,
      initialValue
    );

    return () => {
      if (globalStateRegistration.current) {
        globalStateRegistration.current.deregister();
      }
    };
  }, [key, initialValue]);

  // Applied synchronize() method to pass as a callback.
  const synchronize = useCallback(() => {
    return _synchronize(key, initialValue, setState);
  }, [key, initialValue]);
  // Recover state from IndexedDB whenever user re-focuses on the tab/window
  // this app is running in. This ensures state is synchronized across windows
  // and tabs; the goal is to avoid situations where a tab/window has stale state
  // and the user accidentally interacts with it (causing IndexedDB to be overridden
  // with the stale data).
  useAppFocus(synchronize);

  const persistentSetState = useCallback(
    (newState) => {
      const newStateValue =
        typeof newState === "function" ? newState(state) : newState;

      // Save to IndexedDB
      set(key, newStateValue).then(() => {
        setState(newStateValue);

        // notify all other subscribers that this piece of global state has changed
        if (globalStateRegistration.current) {
          globalStateRegistration.current.set(newStateValue);
        }
      });
    },
    [state, key]
  );

  return [state, persistentSetState];
}
