import * as Ach from "./types";

/**
 * @john.pyrik:
 * Inconsistency Score: Consistent with the principle of refuting hypotheses,
 * only the Inconsistent evidence is counted. The Inconsistency Score for each
 * hypothesis is the sum of the Inconsistent and Very Inconsistent ratings for
 * that hypothesis.
 *
 * The hypothesis with the highest Inconsistency Score is the least likely.
 * The one with the lowest Inconsistency Score is the most likely.
 *
 * @param hypothesisCount The number of hypotheses in the project
 * @param consistencyRatings
 */
export function computeInconsistencyScores(
  hypothesisCount: number,
  consistencyRatings: Ach.ConsistencyRating[][]
) {
  // Each hypothesis gets an inconsistency score, all scores start at 0.
  let inconsistencyScores = Array(hypothesisCount).fill(0);

  // An Inconsistent item of evidence counts as 1 and a Very Inconsistent item
  // counts as 2.
  consistencyRatings.forEach((ratings, hypothesisIndex) => {
    ratings.forEach((rating) => {
      if (rating === Ach.ConsistencyRating.I) {
        inconsistencyScores[hypothesisIndex] -= 1;
      } else if (rating === Ach.ConsistencyRating.II) {
        inconsistencyScores[hypothesisIndex] -= 2;
      }
    });
  });

  return inconsistencyScores;
}

/**
 * @john.pyrik:
 * Weighted Inconsistency Score: This is like the Inconsistency Score, except
 * that the value of each Inconsistent or Very Inconsistent rating is adjusted
 * according to the Credibility and Relevance of that item of evidence.
 *
 * If the Credibility and Relevance are both rated Medium, the value of the
 * Inconsistent or Very Inconsistent rating remains unchanged. If both the
 * Credibility and Relevance are High, the value of the Inconsistent rating
 * is doubled. Inconsistent then counts as 2, and Very Inconsistent counts as 4.
 *
 * If both Credibility and Relevance are Low, the value of an Inconsistent or
 * Very Inconsistent rating is cut in half to .5 and 1, respectively.
 * This means that a highly credible item of Very Inconsistent evidence that
 * gets to the heart of the analytical problem may count eight times as much
 * as an Inconsistent item of uncertain Credibility and peripheral Relevance.
 * @param hypothesisCount The number of hypotheses in the project
 * @param evidenceToCredibility
 * @param evidenceToRelevance
 * @param consistencyRatings
 */
export function computeWeightedInconsistencyScores(
  hypothesisCount: number,
  evidenceToCredibility: Ach.Credibility[],
  evidenceToRelevance: Ach.Relevance[],
  consistencyRatings: Ach.ConsistencyRating[][]
) {
  // Each hypothesis gets an inconsistency score, all scores start at 0.
  let inconsistencyScores = Array(hypothesisCount).fill(0);

  consistencyRatings.forEach((ratings, hypothesisIndex) => {
    ratings.forEach((rating, evidenceIndex) => {
      const credibility = evidenceToCredibility[evidenceIndex];
      const relevance = evidenceToRelevance[evidenceIndex];

      let multiplier = 1;

      if (
        credibility === Ach.Credibility.High &&
        relevance === Ach.Relevance.High
      ) {
        multiplier = 2;
      } else if (
        credibility === Ach.Credibility.Low &&
        relevance === Ach.Relevance.Low
      ) {
        multiplier = 0.5;
      }

      if (rating === Ach.ConsistencyRating.I) {
        inconsistencyScores[hypothesisIndex] -= 1 * multiplier;
      } else if (rating === Ach.ConsistencyRating.II) {
        inconsistencyScores[hypothesisIndex] -= 2 * multiplier;
      }
    });
  });

  return inconsistencyScores;
}
