import { dequal } from "dequal";

type ChangeHandler = (newValue: any) => any;

/** An object holding a value and an array of functions to call when that value changes. */
interface GlobalStateEntry {
  value: any;
  changeHandlers: ChangeHandler[];
}

/**
 * An object holding 'global' state managed by the usePersistentState() hook.
 */
interface GlobalState {
  [key: string]: GlobalStateEntry;
}
let globalState: GlobalState = {};

/** The object returned by registerGlobalState(). */
export interface GlobalStateRegistration {
  /** Unsubscribe from changes */
  deregister: () => void;
  /** Set a new value and notify other subscribers of the change */
  set: (newValue: any) => void;
}

/**
 * Adds a new key/value to globalState or adds a new subscriber to a key/value that already exists.
 *
 * @param key A unique identifier for the value being stored
 * @param changeHandler A function to be called if the value accessed by the given key is changed
 *  by a different subscriber.
 * @param initialValue The value to use if the key/value does not yet exist in globalState
 */
export function registerGlobalState(
  key: string,
  changeHandler: ChangeHandler,
  initialValue: any
): GlobalStateRegistration {
  if (!globalState[key]) {
    // No global state entry for this key yet, so add one.
    globalState[key] = { changeHandlers: [], value: initialValue };
  }
  // This key has an entry already, so add a new subscriber.
  globalState[key].changeHandlers.push(changeHandler);

  return {
    deregister() {
      // Remove the change handler we just added
      let { changeHandlers } = globalState[key];
      let index = changeHandlers.indexOf(changeHandler);
      if (index > -1) {
        changeHandlers.splice(index, 1);
      }
    },
    set(newValue: any) {
      const { value, changeHandlers } = globalState[key];

      if (!dequal(value, newValue)) {
        globalState[key].value = newValue;
        // Notify all subscribers (except for the one who caused the change)
        changeHandlers.forEach((handler: ChangeHandler) => {
          if (handler !== changeHandler) {
            changeHandler(newValue);
          }
        });
      }
    },
  };
}
