import { Link } from "@reach/router";

interface HeaderProps {
  linkText: string;
  link: string;
}

export default function Header({ linkText, link }: HeaderProps) {
  return (
    <h1 className="f1 lh-title">
      ACH
      <small className="f6 black-60 db mb2">
        <Link className="link hover-black pointer" to={link}>
          {linkText}
        </Link>
      </small>
    </h1>
  );
}
