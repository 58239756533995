import { navigate } from "@reach/router";

import * as Ach from "../../../ach";

interface ProjectListItemProps {
  project: Ach.Project;
  index: number;
  handleProjectDeleteButtonClick: any;
}

const ProjectListItem = ({
  project,
  index,
  handleProjectDeleteButtonClick,
}: ProjectListItemProps) => {
  const handleClick = () => {
    navigate(`/offline/projects/${project.id}`);
  };
  const handleDeleteButtonClick = () => {
    handleProjectDeleteButtonClick(index, project.id);
  };
  return (
    <li key={index} className="ph3 pv3 bb b--light-silver cf">
      <span onClick={handleClick} className="hover-gray pointer">
        {project === null || project.title === ""
          ? "Untitled project"
          : project.title}
      </span>
      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-red fr b--none pointer"
        onClick={handleDeleteButtonClick}
      >
        Delete
      </button>
    </li>
  );
};

interface ProjectListProps {
  projects: Ach.Project[];
  handleProjectDeleteButtonClick: any;
  addSampleProject: any;
}

export default function ProjectList({
  projects,
  handleProjectDeleteButtonClick,
  addSampleProject,
}: ProjectListProps) {
  if (projects.length === 0) {
    return (
      <p className="f5 lh-copy">
        No projects found. Click{" "}
        <span
          className="pointer"
          style={{ color: "blue" }}
          onClick={addSampleProject}
        >
          here to load a sample project!
        </span>
      </p>
    );
  }

  const projectItems = projects.map((project: Ach.Project, index: number) => (
    <ProjectListItem
      key={project.id}
      project={project}
      index={index}
      handleProjectDeleteButtonClick={handleProjectDeleteButtonClick}
    />
  ));

  return (
    <ul className="list pl0 ml0 center mw100 ba b--light-silver br2">
      {projectItems}
    </ul>
  );
}
