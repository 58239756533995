import EvidenceTableRow from "./EvidenceTableRow";
import * as Ach from "../../../../ach";

interface EvidenceTableProps {
  hypotheses: string[];
  evidence: string[];
  evidenceToCredibility: Ach.Credibility[];
  evidenceToRelevance: Ach.Relevance[];
  consistencyRatings: Ach.ConsistencyRating[][];
  inconsistencyScores: number[];
  wInconsistencyScores: number[];
  dispatch: any;
}

export default function EvidenceTable({
  hypotheses,
  evidence,
  evidenceToCredibility,
  evidenceToRelevance,
  consistencyRatings,
  inconsistencyScores,
  wInconsistencyScores,
  dispatch,
}: EvidenceTableProps) {
  if (evidence.length === 0) {
    return (
      <h4 className="f5 fw6">
        Evidence:{" "}
        <span className="f5 lh-copy normal">
          None yet, click the add button below!
        </span>
      </h4>
    );
  }

  // Generate hypothesis table headers
  const hypothesisHeaders = [...hypotheses].map((hypothesis, i) => {
    const hypothesisId = `H${i}`;
    return (
      <th
        key={i}
        className="fw6 bb b--black-20 tl pb3 pr3 bg-white tc"
        title={hypothesis}
        style={{ cursor: "help" }}
      >
        {hypothesisId}
      </th>
    );
  });

  // Generate table rows for evidence and consistency ratings.
  const tableRows = evidence.map((pieceOfEvidence, i) => {
    const credibility = evidenceToCredibility[i];
    const relevance = evidenceToRelevance[i];
    return (
      <EvidenceTableRow
        key={i}
        index={i}
        evidence={pieceOfEvidence}
        credibility={credibility}
        relevance={relevance}
        consistencyRatings={consistencyRatings}
        dispatch={dispatch}
      />
    );
  });

  // Generate table elements to display inconsistency scoring
  const leastInconsistent = Math.max(...inconsistencyScores);
  const mostInconsistent = Math.min(...inconsistencyScores);
  const inconsistencyScoreCells = inconsistencyScores.map((score, index) => {
    let extraStyle = "";
    if (score === leastInconsistent) {
      extraStyle = "green";
    } else if (score === mostInconsistent) {
      extraStyle = "red";
    }
    return (
      <td key={index} className={`pv3 pr3 bb b--black-20 tc b ${extraStyle}`}>
        {score}
      </td>
    );
  });

  // Now for weighted inconsistency scores
  const wLeastInconsistent = Math.max(...wInconsistencyScores);
  const wMostInconsistent = Math.min(...wInconsistencyScores);
  const wInconsistencyScoreCells = wInconsistencyScores.map((score, index) => {
    let extraStyle = "";
    if (score === wLeastInconsistent) {
      extraStyle = "green";
    } else if (score === wMostInconsistent) {
      extraStyle = "red";
    }
    return (
      <td key={index} className={`pv3 pr3 bb b--black-20 tc b ${extraStyle}`}>
        {score}
      </td>
    );
  });

  return (
    <div className="overflow-auto mt4 mb2 mh0-l">
      <table className="f5 w-100 mw8 center" cellSpacing="0">
        <thead>
          <tr>
            <th className="fw6 bb b--black-20 tl pb3 pr3 bg-white">Evidence</th>
            <th className="fw6 bb b--black-20 tc pb3 pr3 bg-white">
              Credibility
            </th>
            <th className="fw6 bb b--black-20 tc pb3 pr3 bg-white">
              Relevance
            </th>
            {hypothesisHeaders}
          </tr>
        </thead>

        <tbody className="lh-copy">
          {tableRows}
          <tr>
            <td className="pv3 pr3 bb b--black-20 b">Inconsistency Scores</td>
            <td className="pv3 pr3 bb b--black-20"></td>
            <td className="pv3 pr3 bb b--black-20"></td>
            {inconsistencyScoreCells}
          </tr>
          <tr>
            <td className="pv3 pr3 bb b--black-20 b">
              Weighted Inconsistency Scores
            </td>
            <td className="pv3 pr3 bb b--black-20"></td>
            <td className="pv3 pr3 bb b--black-20"></td>
            {wInconsistencyScoreCells}
          </tr>
        </tbody>
      </table>
    </div>
  );
}
