import { Router } from "@reach/router";

import ErrorBoundary from "./components/ErrorBoundary";
import About from "./routes/About";
import Projects from "./routes/Projects";
import Project from "./routes/Project";

export default function App() {
  return (
    <ErrorBoundary>
      <Router>
        <Projects path="/" />
        <Projects path="/offline/projects" />
        <Project path="/offline/projects/:id" />
        <About path="/about" />
      </Router>
    </ErrorBoundary>
  );
}
