import { useState, useEffect } from "react";

import useEventListener from "./useEventListener";

/**
 * Give this hook a callback and it will be called whenever the user returns to
 * the page (either from another tab or another window).
 */
export default function useAppFocus(callback: any) {
  const [shouldCall, setShouldCall] = useState(false);

  // The 'visibilitychange' event occurs on the document when the user focuses
  // on the tab we're running in.
  useEventListener(
    "visibilitychange",
    () => {
      if (document.hidden || shouldCall) {
        return;
      }
      setShouldCall(true);
    },
    document
  );

  // Unfortunately, the Page Visibility API only fires a visibilitychange event
  // when changing tabs in the same window. If you have two browser windows open
  // and switch between them, the "visibilitychange" event will not fire
  // (not sure if this is a bug or the intended behaviour...).
  // Listening for focus events on window seems to workaround this problem.
  useEventListener(
    "focus",
    () => {
      if (shouldCall) {
        return;
      }
      setShouldCall(true);
    },
    window
  );

  useEffect(() => {
    if (shouldCall) {
      callback();
      setShouldCall(false);
    }
  }, [shouldCall, callback]);
}
