import * as Ach from "../../../ach";
import usePersistentReducer from "../../../hooks/usePersistentReducer";

import StatelessProject from "./StatelessProject";

const fallbackInitialState = Ach.Reducer.init();

interface PersistentProjectProps {
  id: string;
  /**
   * If project has already been pulled from IndexedDB (such as by <Project>),
   * then it is an ideal initial state. Otherwise, use the fallback initial state.
   */
  initialState?: Ach.Project;
}

/**
 * <PersistentProject/> manages the state displayed by <StatelessProject/>.
 *
 * <PersistentProject/> should only receive valid project IDs. This is important
 * because instantiating <PersistentProject> with an unused ID will result in
 * a new project being created and saved to IndexedDB.
 *
 * The project will exist in IndexedDB, but will not appear in the list of
 * projects because its ID is not stored in the 'projectIds' that <Projects>
 * manages.
 */
export default function PersistentProject({
  id,
  initialState,
}: PersistentProjectProps) {
  const [state, dispatch] = usePersistentReducer(
    id,
    Ach.Reducer.reducer,
    initialState ?? fallbackInitialState
  );

  return <StatelessProject state={state} dispatch={dispatch} />;
}
