import { useMemo } from "react";
import { saveAs } from "file-saver";
import sanitize from "sanitize-filename";
import cogoToast from "cogo-toast";

import * as Ach from "../../../ach";
import Header from "../../../components/Header";
import Title from "./Title";
import HypothesisList from "./HypothesisList";
import EvidenceTable from "./EvidenceTable/EvidenceTable";
import FileUploader from "../../../components/FileUploader";
import Footer from "../../../components/Footer";

interface StatelessProjectProps {
  state: Ach.Project;
  dispatch: any;
}

/**
 * <StatelessProject/> controls how the project UI looks without having to deal
 * with any of the persistent state (that job is delegated to the component
 * passing in state and dispatch).
 *
 * The original motivation behind <StatelessProject/> was to make it easy to
 * render a placeholder project to display while data loads.
 */
export default function StatelessProject({
  state,
  dispatch,
}: StatelessProjectProps) {
  const {
    title,
    hypotheses,
    evidence,
    evidenceIndexToCredibility,
    evidenceIndexToRelevance,
    consistencyRatings,
  } = state;

  const handleAddHypothesisClick = () => {
    dispatch({ type: "hypothesis.add", hypothesis: "Untitled hypothesis" });
  };

  const handleAddEvidenceClick = () => {
    dispatch({ type: "evidence.add", evidence: "Untitled evidence" });
  };

  const handleExportButtonClick = () => {
    const blob = new Blob([JSON.stringify(state, null, 2)], {
      type: "application/json;charset=utf-8",
    });
    // If the project's title is "", substitute with "Untitled Project" to avoid
    // "json.json" from being saved.
    saveAs(blob, sanitize(`${title === "" ? "Untitled Project" : title}.json`));
    cogoToast.success("Project exported");
  };

  const handleUploadButtonClick = () => {
    // If the project has data in it, ask the user to confirm the import action.
    if (title !== "" || hypotheses.length > 0 || evidence.length > 0) {
      return window.confirm(
        "Are you sure you want to import a project? The imported project will replace the current project."
      );
    }

    // The project has no data, so importing is non-destructive.
    return true;
  };

  const handleUpload = (file: string) => {
    // Try to parse uploaded file
    try {
      const parsedProject = Ach.parseProject(JSON.parse(file));
      // All clear for importing this project.
      dispatch({ type: "import", project: parsedProject });
    } catch (error) {
      cogoToast.error(
        "Import failed! The uploaded data does not look like an Ach.Project."
      );
      console.error(error);
      return;
    }

    cogoToast.success(`Upload complete`);
  };

  const handleClear = () => {
    const confirmation = window.confirm(
      "Are you sure you want to clear this project? All data for this project will be erased."
    );
    if (confirmation) {
      dispatch({ type: "clear" });
      cogoToast.success("Project cleared");
    }
  };

  // Compute inconsistency scores
  const inconsistencyScores = Ach.utils.computeInconsistencyScores(
    hypotheses.length,
    consistencyRatings
  );
  const wInconsistencyScores = Ach.utils.computeWeightedInconsistencyScores(
    hypotheses.length,
    evidenceIndexToCredibility,
    evidenceIndexToRelevance,
    consistencyRatings
  );

  // The hypothesis with the highest inconsistency score is the most likely;
  // to highlight this hypothesis in the list of hypotheses, find the index of
  // the highest score.
  const hypothesisHighlightIndex = useMemo(() => {
    // If there are no hypotheses, none can be highlighted.
    if (hypotheses.length === 0) return undefined;

    // If there is no evidence, don't bother highlighting anything.
    if (evidence.length === 0) return undefined;

    // Find the highest inconsistency score
    const highestScore = Math.max(...inconsistencyScores);

    // If there are multiple highest scores (i.e. there are ties), then don't
    // highlight anything.
    if (inconsistencyScores.filter((s) => s === highestScore).length > 1) {
      return undefined;
    }

    // The index of the highestScore is also the index of the hypothesis that
    // should be highlighted.
    const highestScoreIndex = inconsistencyScores.findIndex(
      (s) => s === highestScore
    );
    return highestScoreIndex;
  }, [inconsistencyScores, hypotheses.length, evidence.length]);

  return (
    <div
      className="helvetica pa3 ma3"
      style={{ margin: "0 auto", maxWidth: "960px" }}
    >
      <Header link="/offline/projects" linkText="<- projects" />
      <Title title={state.title} dispatch={dispatch} />

      <HypothesisList
        hypotheses={state.hypotheses}
        dispatch={dispatch}
        highlightIndex={hypothesisHighlightIndex}
      />
      <div className="flex justify-end">
        <button
          className="f6 dim br2 ph3 pv2 mb2 white bg-near-black mt1 b--none pointer"
          onClick={handleAddHypothesisClick}
        >
          Add hypothesis
        </button>
      </div>

      <EvidenceTable
        hypotheses={state.hypotheses}
        evidence={state.evidence}
        evidenceToCredibility={state.evidenceIndexToCredibility}
        evidenceToRelevance={state.evidenceIndexToRelevance}
        consistencyRatings={state.consistencyRatings}
        inconsistencyScores={inconsistencyScores}
        wInconsistencyScores={wInconsistencyScores}
        dispatch={dispatch}
      />
      <button
        className="f6 dim br2 ph3 pv2 mb3 dib white bg-near-black mt1 fr b--none pointer"
        onClick={handleAddEvidenceClick}
      >
        Add evidence
      </button>

      <details className="pointer">
        <summary style={{ lineHeight: "normal" }}>Advanced</summary>
        <div className="mb3 mt3">
          <FileUploader
            uploadButtonLabel="upload json"
            onUploadButtonClick={handleUploadButtonClick}
            onUpload={handleUpload}
          />
          <button
            className="f6 dim br2 ph3 pv2 mb2 dib white bg-near-black ml2 b--none pointer"
            onClick={handleExportButtonClick}
          >
            export json
          </button>
          <button
            className="f6 dim br2 ph3 pv2 mb2 dib white bg-red ml2 b--none pointer"
            onClick={handleClear}
          >
            clear
          </button>
        </div>
      </details>

      <Footer />
    </div>
  );
}
