import { useState, useEffect } from "react";

interface StaticTitleProps {
  title: string;
  onClick: any;
}

/**
 * Simply renders the title as an <h2>
 */
const StaticTitle = ({ title, onClick }: StaticTitleProps) => {
  return (
    <h2
      className="f3 lh-copy measure hover-gray"
      style={{ cursor: "text" }}
      onClick={onClick}
    >
      {title === "" ? "Untitled project" : title}
    </h2>
  );
};

interface EditedTitleProps {
  title: string;
  onChange: any;
  onCancel: any;
  onSave: any;
}

/**
 * Renders the title in editing mode.
 */
const EditedTitle = ({
  title,
  onChange,
  onCancel,
  onSave,
}: EditedTitleProps) => {
  return (
    <>
      <textarea
        className="input-reset ba b--black-20 pa2 mb2 db w-100"
        value={title}
        onChange={onChange}
      />
      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-red ml2 b--none pointer"
        onClick={onCancel}
      >
        cancel
      </button>
      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-near-black ml2 b--none pointer"
        onClick={onSave}
      >
        save
      </button>
    </>
  );
};

interface TitleProps {
  title: string;
  dispatch: any;
}

/**
 * The project title.
 * Starts in static mode and transforms into an editable form on click.
 */
export default function Title({ title, dispatch }: TitleProps) {
  const [editing, setEditing] = useState(false);
  const [editedTitle, setEditedTitle] = useState(title);

  const toggleEditing = () => setEditing((current) => !current);
  const handleTitleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedTitle(event.target.value);
  };
  const handleCancelButtonClick = () => {
    setEditing(false);
    setEditedTitle(title);
  };
  const handleSaveButtonClick = () => {
    setEditing(false);
    dispatch({ type: "title.set", title: editedTitle });
  };

  // If title changes externally, editedTitle needs to be reset otherwise entering
  // edit mode will show the title as it was when this component was instantiated.
  useEffect(() => {
    setEditedTitle(title);
  }, [title]);

  if (!editing) {
    return <StaticTitle title={title} onClick={toggleEditing} />;
  } else {
    return (
      <EditedTitle
        title={editedTitle}
        onChange={handleTitleEdit}
        onCancel={handleCancelButtonClick}
        onSave={handleSaveButtonClick}
      />
    );
  }
}
