import { useState, useEffect } from "react";

interface StaticHypothesisListItemProps {
  index: number;
  hypothesis: string;
  onClick: any;
  highlighted?: boolean;
}

const StaticHypothesisListItem = ({
  index,
  hypothesis,
  onClick,
  highlighted,
}: StaticHypothesisListItemProps) => {
  const formattedKey = `H${index}:`;
  return (
    <dl key={hypothesis} className="f5 lh-title mv2" onClick={onClick}>
      <dt className="dib b">{formattedKey}</dt>
      <dd className="dib ml1 hover-gray">
        {highlighted ? (
          <mark title="this is the most likely hypothesis">{hypothesis}</mark>
        ) : (
          hypothesis
        )}
      </dd>
    </dl>
  );
};

interface EditedHypothesisListItemProps {
  editedHypothesis: string;
  onChange: any;
  onCancel: any;
  onSave: any;
  onDelete: any;
}

const EditedHypothesisListItem = ({
  editedHypothesis,
  onChange,
  onCancel,
  onSave,
  onDelete,
}: EditedHypothesisListItemProps) => {
  return (
    <div className="mb2">
      <textarea
        className="input-reset ba b--black-20 pa2 mb2 db w-100"
        value={editedHypothesis}
        onChange={onChange}
      />

      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-red ml2 b--none pointer"
        onClick={onCancel}
      >
        cancel
      </button>

      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-near-black ml2 b--none pointer"
        onClick={onSave}
      >
        save
      </button>

      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-red ml2 fr b--none pointer"
        onClick={onDelete}
      >
        delete
      </button>
    </div>
  );
};

interface HypothesisListItemProps {
  index: number;
  hypothesis: string;
  dispatch: any;
  highlighted?: boolean;
}

export default function HypothesisListItem({
  index,
  hypothesis,
  dispatch,
  highlighted,
}: HypothesisListItemProps) {
  const [editing, setEditing] = useState(false);
  const [editedHypothesis, setEditedHypothesis] = useState(hypothesis);

  const toggleEditing = () => setEditing((current) => !current);
  const handleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedHypothesis(event.target.value);
  };
  const handleCancelButtonClick = () => {
    setEditing(false);
    setEditedHypothesis(hypothesis);
  };
  const handleSaveButtonClick = () => {
    setEditing(false);
    dispatch({
      type: "hypothesis.replaceByIndex",
      index,
      newHypothesis: editedHypothesis,
    });
  };
  const handleDeleteButtonClick = () => {
    setEditing(false);
    dispatch({
      type: "hypothesis.deleteByIndex",
      index,
    });
  };

  // If the hypothesis changes externally, editedHypothesis needs to be reset
  // otherwise entering edit mode will show the hypothesis as it was when this
  // component was instantiated.
  useEffect(() => {
    setEditedHypothesis(hypothesis);
  }, [hypothesis]);

  if (!editing) {
    return (
      <StaticHypothesisListItem
        index={index}
        hypothesis={hypothesis}
        onClick={toggleEditing}
        highlighted={highlighted}
      />
    );
  } else {
    return (
      <EditedHypothesisListItem
        editedHypothesis={editedHypothesis}
        onChange={handleEdit}
        onCancel={handleCancelButtonClick}
        onSave={handleSaveButtonClick}
        onDelete={handleDeleteButtonClick}
      />
    );
  }
}
