import { useRef } from "react";

interface FileUploaderProps {
  uploadButtonLabel: string;
  onUploadButtonClick(): boolean;
  onUpload: any;
}

export default function FileUploader({
  uploadButtonLabel,
  onUploadButtonClick,
  onUpload,
}: FileUploaderProps) {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadButtonClick = () => {
    if (fileInputRef.current && onUploadButtonClick()) {
      fileInputRef.current.click();
    }
  };

  //React.ChangeEvent<HTMLInputElement>
  const onFileUpload = (e: any) => {
    const { files } = e.target;
    if (files.length === 0) {
      return;
    }

    // Prepare the FileReader, then read the first file. If multiple files were
    // uploaded, they are ignored.
    const reader = new FileReader();
    reader.addEventListener("load", () => {
      const readFile = reader.result;
      // Only text files are expected to be read.
      if (typeof readFile !== "string") {
        return;
      }
      onUpload(readFile);

      // Reset (necessary to avoid a bug where the same file uploaded twice
      // does nothing)
      // https://stackoverflow.com/a/42192710/6591491
      e.target.value = null;
    });

    reader.readAsText(files[0]);
  };

  return (
    <>
      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-near-black w4 tc b--none pointer"
        onClick={handleUploadButtonClick}
      >
        {uploadButtonLabel}
      </button>
      <input
        type="file"
        ref={fileInputRef}
        onChange={onFileUpload}
        style={{
          visibility: "hidden",
          display: "none",
          position: "absolute",
          height: 0,
        }}
      />
    </>
  );
}
