import { init } from "./reducer";

export default function createSampleProject() {
  let sampleProject = init();
  return {
    ...sampleProject,
    title:
      "Will Iraq Retaliate for US Bombing of Its Intelligence Headquarters? (1993)",
    hypotheses: [
      "Iraq will not retaliate.",
      "It will sponsor some minor terrorist actions.",
      "Iraq is planning a major terrorist attack, perhaps against one or more CIA installations.",
    ],
    evidence: [
      "Saddam public statement of intent not to retaliate.",
      "Absense of terrorist offensive during the 1991 Gulf War.",
      "Assumption that Iraq would not want to provoke another US attack.",
      "Increase in frequency/length of monitored Iraqi agent radio broadcasts.",
      "Iraqi embassies instructed to take increased security precautions.",
      "Assumption that failure to retaliate would be unacceptable loss of face for Saddam.",
    ],
    evidenceIndexToCredibility: [0, 0, 1, 2, 2, 2, 1],
    evidenceIndexToRelevance: [1, 0, 1, 1, 1, 2, 1],
    consistencyRatings: [
      [3, 3, 3, 1, 1, 0],
      [3, 3, 3, 3, 3, 3],
      [3, 1, 1, 3, 3, 3],
    ],
  };
}
