import { useState, useEffect } from "react";

import * as Ach from "../../../../ach";

interface StaticEvidenceTitleProps {
  evidence: string;
  onClick: any;
}
const StaticEvidenceTitle = ({
  evidence,
  onClick,
}: StaticEvidenceTitleProps) => {
  return (
    <dl className="f5 lh-title mv2" onClick={onClick}>
      <dd className="dib ml1 hover-gray">{evidence}</dd>
    </dl>
  );
};

interface EditedEvidenceTitleProps {
  editedEvidence: string;
  onChange: any;
  onCancel: any;
  onSave: any;
  onDelete: any;
}

const EditedEvidenceTitle = ({
  editedEvidence,
  onChange,
  onCancel,
  onSave,
  onDelete,
}: EditedEvidenceTitleProps) => {
  return (
    <div className="mb2" style={{ minWidth: "20em" }}>
      <textarea
        className="input-reset ba b--black-20 pa2 mb2 db w-100"
        value={editedEvidence}
        onChange={onChange}
      />

      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-red ml2 b--none pointer"
        onClick={onCancel}
      >
        cancel
      </button>

      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-near-black ml2 b--none pointer"
        onClick={onSave}
      >
        save
      </button>

      <button
        className="f6 dim br2 ph3 pv2 mb2 dib white bg-red ml2 fr-ns b--none pointer"
        onClick={onDelete}
      >
        delete
      </button>
    </div>
  );
};

interface EditableEvidenceTitleProps {
  index: number;
  evidence: string;
  dispatch: any;
}

const EditableEvidenceTitle = ({
  index,
  evidence,
  dispatch,
}: EditableEvidenceTitleProps) => {
  const [editing, setEditing] = useState(false);
  const [editedEvidence, setEditedEvidence] = useState(evidence);

  const toggleEditing = () => setEditing((current) => !current);
  const handleEdit = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditedEvidence(event.target.value);
  };
  const handleCancelButtonClick = () => {
    setEditing(false);
    setEditedEvidence(evidence);
  };
  const handleSaveButtonClick = () => {
    setEditing(false);
    dispatch({
      type: "evidence.replaceByIndex",
      index: index,
      newEvidence: editedEvidence,
    });
  };
  const handleDeleteButtonClick = () => {
    setEditing(false);
    dispatch({
      type: "evidence.deleteByIndex",
      index: index,
    });
  };

  // If evidence changes externally, editedEvidence needs to be reset
  // otherwise entering edit mode will show the evidence as it was when this
  // component was instantiated.
  useEffect(() => {
    setEditedEvidence(evidence);
  }, [evidence]);

  if (!editing) {
    return <StaticEvidenceTitle evidence={evidence} onClick={toggleEditing} />;
  } else {
    return (
      <EditedEvidenceTitle
        editedEvidence={editedEvidence}
        onChange={handleEdit}
        onCancel={handleCancelButtonClick}
        onSave={handleSaveButtonClick}
        onDelete={handleDeleteButtonClick}
      />
    );
  }
};

interface EvidenceTableRowProps {
  index: number;
  evidence: string;
  credibility: Ach.Credibility;
  relevance: Ach.Relevance;
  consistencyRatings: Ach.ConsistencyRating[][];
  dispatch: any;
}

export default function EvidenceTableRow({
  index,
  evidence,
  credibility,
  relevance,
  consistencyRatings,
  dispatch,
}: EvidenceTableRowProps) {
  const handleCredibilityChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newCredibility = Number(event.target.value);
    dispatch({
      type: "evidence.credibility.set",
      index: index,
      credibility: newCredibility,
    });
  };

  const handleRelevanceChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const newRelevance = Number(event.target.value);
    dispatch({
      type: "evidence.relevance.set",
      index: index,
      relevance: newRelevance,
    });
  };

  const ratingCells = consistencyRatings.map((ratings, hypothesisIndex) => {
    // The ratings for this piece of evidence.
    const rating = ratings[index];

    const handleRatingChange = (
      event: React.ChangeEvent<HTMLSelectElement>
    ) => {
      const newRating = Number(event.target.value);
      dispatch({
        type: "consistencyRating.set",
        hypothesisIndex,
        evidenceIndex: index,
        rating: newRating,
      });
    };

    return (
      <td key={hypothesisIndex} className="pv3 pr3 bb b--black-20 tc">
        <select value={rating} onChange={handleRatingChange}>
          <option value={Ach.ConsistencyRating.II}>II</option>
          <option value={Ach.ConsistencyRating.I}>I</option>
          <option value={Ach.ConsistencyRating.N}>N</option>
          <option value={Ach.ConsistencyRating.C}>C</option>
          <option value={Ach.ConsistencyRating.CC}>CC</option>
        </select>
      </td>
    );
  });

  return (
    <tr>
      <td className="pv3 pr3 bb b--black-20 tl">
        <EditableEvidenceTitle
          index={index}
          evidence={evidence}
          dispatch={dispatch}
        />
      </td>
      <td className="pv3 pr3 bb b--black-20 tc">
        <select value={credibility} onChange={handleCredibilityChange}>
          <option value={Ach.Credibility.Low}>Low</option>
          <option value={Ach.Credibility.Medium}>Medium</option>
          <option value={Ach.Credibility.High}>High</option>
        </select>
      </td>
      <td className="pv3 pr3 bb b--black-20 tc">
        <select value={relevance} onChange={handleRelevanceChange}>
          <option value={Ach.Relevance.Low}>Low</option>
          <option value={Ach.Relevance.Medium}>Medium</option>
          <option value={Ach.Relevance.High}>High</option>
        </select>
      </td>
      {ratingCells}
    </tr>
  );
}
