import { useState, useEffect } from "react";
import { get } from "idb-keyval";

import * as Ach from "../../ach";
import Header from "../../components/Header";
import PersistentProject from "./components/PersistentProject";

enum ProjectSearchStatus {
  Loading,
  Error,
  Success,
}

interface ProjectSearch {
  status: ProjectSearchStatus;
  error?: Error;
  project?: Ach.Project;
}

interface ProjectGateProps {
  id?: string;
  path?: string;
}

/**
 * The router will send all "project-looking" paths to <Project> but if the path
 * is missing an ID or if the ID refers to a project that doesn't exist, then
 * an error should be displayed.
 */
export default function Project({ id, ...rest }: ProjectGateProps) {
  const [projectSearch, setProjectSearch] = useState<ProjectSearch>({
    status: ProjectSearchStatus.Loading,
  });

  useEffect(() => {
    if (id === undefined) {
      setProjectSearch({
        status: ProjectSearchStatus.Error,
        error: new Error(`No project ID given.`),
      });
      return;
    }

    get(id)
      .then((value) => {
        if (value === undefined) {
          setProjectSearch({
            status: ProjectSearchStatus.Error,
            error: new Error(`Project ID: ${id} could not be found.`),
          });
          return;
        }

        setProjectSearch({
          status: ProjectSearchStatus.Success,
          project: value,
        });
      })
      .catch((error) => {
        setProjectSearch({ status: ProjectSearchStatus.Error, error });
      });
  }, [id]);

  switch (projectSearch.status) {
    case ProjectSearchStatus.Loading:
      // Loading is so quick (at least right now), that it doesn't really make
      // sense to display a loading indicator (blink and you'll miss it).
      return null;
    case ProjectSearchStatus.Error:
      console.error(projectSearch.error);
      return (
        <div
          className="helvetica pa3 ma3"
          style={{ margin: "0 auto", maxWidth: "960px" }}
        >
          <Header link="/offline/projects" linkText="<- projects" />
          <h3 className="f3 lh-title">Project not found</h3>
          <p className="f5 lh-copy measure">
            The project with ID <span className="code">{id}</span> could not be
            found in your device's local storage.
          </p>
          <p className="f5 lh-copy measure">
            Are you sure you're on the right device and that the project has not
            been deleted?
          </p>
        </div>
      );
    case ProjectSearchStatus.Success:
      return (
        <PersistentProject
          id={id!}
          initialState={projectSearch.project}
          {...rest}
        />
      );
  }
}
